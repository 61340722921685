import React, {
  FC,
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import {
  WalletDisconnectButton,
  WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";

import { useStakedNfts } from "./hooks/useStakedNfts";
import { useUnstakedNfts } from "./hooks/useUnstakedNfts";

import { PublicKey } from "@solana/web3.js";
import {
  createStakeTokenTransaction,
  createUnstakeTokenTransaction,
  createWithdrawRewardTransaction,
  createStakeUpgradeTokenTransaction,
  createAdminUnstakeTokenTransaction,
  getRewardCalculator,
  getStakeDataAddress,
  getStakedDataByOwner,
  getAllTokens,
  calculateRewards,
  StakedData,
  UnstakedData,
  RewardCalculator,
} from "./util/staking";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import * as SPLToken from "@solana/spl-token";
import PieChart from "./util/piechart";
import {
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
  getSolletWallet,
  getSolletExtensionWallet,
} from "@solana/wallet-adapter-wallets";
import { Metadata } from "@metaplex-foundation/mpl-token-metadata";
import {
  Connection,
  Transaction,
  clusterApiUrl,
  SystemProgram,
  TransactionInstruction,
  LAMPORTS_PER_SOL,
  Keypair,
  sendAndConfirmTransaction,
} from "@solana/web3.js";
// import { useRepayRoyalties } from "./util/useRepayRoyalties";
import {
  Navbar,
  Container,
  Nav,
  Row,
  Col,
  Card,
  Button,
  Spinner,
} from "react-bootstrap";
import logo from "../src/assest/DC_-_Full_Logo_-_Red.png";

import logo_red_landing_page from "../src/assest/DROID_Inverted_Horizontal_-_Red.png";
import logo_cream from "../src/assest/DROID_Stacked_-_Cream.png";
import brand from "../src/assest/brand.gif";
import menu_discord from "../src/assest/discord.png";
import menu_twitter from "../src/assest/twitter.png";
import menu_magic_eden from "../src/assest/magic-eden.png";
import down_arrow_svg from "../src/assest/down-arrow-svgrepo-com.svg";

import swirl1 from "../src/assest/Swirl-1-Red.png";
import swirl2 from "../src/assest/Swirl-2-Red.png";

import stack_img from "../src/assest/layers.png";
import earn from "../src/assest/salary.png";

import droid_icon_1 from "../src/assest/DC-Inverted-Background-Icon-Red.png";
import droid_icon_2 from "../src/assest/DC-Inverted-Background-Icon-Cream.png";
import droid_icon_3 from "../src/assest/DC_Inverted_Background_-_Icon_-_Grey.png";

import { Wallet } from "./Wallet";
import { Modal } from "react-bootstrap";
import { Tabs, Tab } from "react-bootstrap-tabs";
import Swal from "sweetalert2";
import moment from "moment";
import { Loader } from "./loader";
import Slider from "react-slick";
import slider1 from "../src/assest/legendaries/6501.png";
import slider2 from "../src/assest/legendaries/6502.png";
import slider3 from "../src/assest/legendaries/6503.png";
import slider4 from "../src/assest/legendaries/6504.png";
import slider5 from "../src/assest/legendaries/6505.png";
import slider6 from "../src/assest/legendaries/6506.png";
import slider7 from "../src/assest/legendaries/6507.png";
import slider8 from "../src/assest/legendaries/6508.png";

// var moon_rank = require('../src/data/droid.json');

// const bs58 = require('bs58')
let rewardsCount: any = 0;

const reward_token_decimal = 9;
const staked_duration = [604800, 2419200, 7257600, 15724800, 31449600, 2629800];

const penalty_duration_1 = 2628000;
const penalty_duration_2 = 7884000;
const penalty_duration_3 = 15768000;
const penalty_duration_4 = 31536000;

const penalty_fees_1 = 1250000000000;
const penalty_fees_2 = 2500000000000;
const penalty_fees_3 = 5000000000000;
const penalty_fees_4 = 10000000000000;

const droid_pool_balance = 200000;

const settings = {
  className: "center",
  centerMode: true,
  infinite: true,
  centerPadding: "60px",
  slidesToShow: 2,
  slidesToScroll: 2,
  speed: 500,
  rows: 3,
  slidesPerRow: 1,
  arrows: true,
};

const PUBLIC_RPC_URL =
  "https://broken-frosty-dream.solana-mainnet.quiknode.pro/fcab33b5c2c8d6c8f06634e9c050cb6d751dcdaa/";

type DisplayEncoding = "utf8" | "hex";
type PhantomEvent = "disconnect" | "connect" | "accountChanged";
type PhantomRequestMethod =
  | "connect"
  | "disconnect"
  | "signTransaction"
  | "signAllTransactions"
  | "signMessage";

interface ConnectOpts {
  onlyIfTrusted: boolean;
}
interface PhantomProvider {
  publicKey: PublicKey;
  isConnected: boolean | null;
  signTransaction: (transaction: Transaction) => Promise<Transaction>;
  signAndSendTransaction: (transaction: Transaction) => Promise<any>;
  signAllTransactions: (transactions: Transaction[]) => Promise<Transaction[]>;
  signMessage: (
    message: Uint8Array | string,
    display?: DisplayEncoding
  ) => Promise<any>;
  connect: (opts?: Partial<ConnectOpts>) => Promise<{ publicKey: PublicKey }>;
  disconnect: () => Promise<void>;
  on: (event: PhantomEvent, handler: (args: any) => void) => void;
  request: (method: PhantomRequestMethod, params: any) => Promise<unknown>;
}
const getProvider = (): PhantomProvider | undefined => {
  if ("solana" in window) {
    const anyWindow: any = window;
    const provider = anyWindow.solana;
    if (provider.isPhantom) {
      // console.log("provider:"+ provider)
      return provider;
    }
  }
  // window.open("https://phantom.app/", "_blank");
};

export const Navigation: FC = () => {
  const { connection } = useConnection();
  const { wallet } = useWallet();
  const [refreshHandle, forceRefresh] = useReducer((x) => !x, true);
  const [stakeMsgDisplay, setStakeMsgDisplay] = useState<boolean>(false);
  const [stakeSuccessDisplay, setStakeSuccessDisplay] =
    useState<boolean>(false);
  const [transfailureStatus, setTransfailureStatus] = useState<boolean>(false);
  const stakedNfts = useStakedNfts(refreshHandle);
  const unstakedNfts = useUnstakedNfts(refreshHandle);
  const [unstakedNftsForTable, setunstakedNftsForTable] = useState([]);
  const [unstakedNftsForRoyalty, setunstakedNftsForRoyalty] = useState([]);
  const [royaltyDueNfts, setRoyaltyDueNfts] = useState([]);
  const [penalityNftForTable, setpenalityNftForTable] = useState([]);
  const [noPenalityNftForTable, setnoPenalityNftForTable] = useState([]);
  const [stakedNftsAddress, setstakedNftsAddress] = useState([]);
  const [stake, setStake] = useState<boolean>(false);
  const [unStake, setUnStake] = useState<boolean>(false);
  const [withdraw, setwithdraw] = useState<boolean>(false);
  const [totalrewardCount, setTotalrewardCount] = useState(0);
  const [stackingDurationIndex, setstackingDurationIndex] = useState("0");
  const [totalRewardEarned, settotalRewardEarned] = useState<any | null>(null);

  const [rewardCalculator, setRewardCalculator] =
    useState<RewardCalculator | null>(null);

  const [rewardDate, setRewardDate] = useState<Date>(new Date());
  const [StakingModal, setStakingModal] = useState(false);
  const [UnStakingModal, setUnStakingModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isInnerLoading, setisInnerLoading] = useState(true);
  const { publicKey, sendTransaction, signAllTransactions } = useWallet();
  const [overAllStackedNft, setoverAllStackedNft] = useState(0);
  const [data, setData] = useState([]);
  const [nftMintForUnstake, setNftMintForUnstake] = useState("");
  const [nftOwnerForUnstake, setNftOwnerForUnstake] = useState("");
  const [vaultTokenDroidBalance, setVaultTokenDroidBalance] = useState(0);
  const [dailyDroidRate, setDailyDroidRate] = useState(0);
  const [moonRank, setMoonRank] = useState([]);

  // useEffect(() => {
  //   setisInnerLoading(true);
  // }, [refreshHandle]);

  // const bytes = bs58.decode('')
  // console.log(bytes)
  const provider = getProvider();

  useEffect(() => {
    getMoonRank();
  }, []);

  const getMoonRank = async () => {
    const recentPlay = fetch("https://api.niftybot.art/static/droid.json")
      .then((recentPlayResp) => {
        if (recentPlayResp && recentPlayResp.status === 200) {
          var abc = recentPlayResp.json();
          return abc;
        } else if (recentPlayResp.status === 202) {
          console.log("failed");
        } else {
          console.log("not working");
        }
      })
      .then((response) => {
        setMoonRank(response);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    if (!rewardCalculator) return;

    let { rewardPeriod } = rewardCalculator;
    let interval = Number.MAX_SAFE_INTEGER;
    if (rewardPeriod < BigInt(Number.MAX_SAFE_INTEGER)) {
      interval = 10;
    }

    let id = setInterval(() => setRewardDate(new Date()), interval);

    return () => clearInterval(id);
  }, [rewardCalculator]);
  const setStakeMsg = (val: boolean): void => {
    //console.log("display msg staking" +val)
    setStakeMsgDisplay(val);
    if (val) {
      setStakeSuccessDisplay(false);
    }
  };
  const transFailure = (val: boolean): void => {
    if (val) {
      setTransfailureStatus(true);
    } else {
      setTransfailureStatus(false);
    }
  };
  const stakeFlag = (val: boolean): void => {
    if (isLoading) setIsLoading(false);
    setStake(val);
    setUnStake(!val);
    setwithdraw(!val);
    if (!stakeMsgDisplay && val && !transfailureStatus) {
      setStakeSuccessDisplay(true);
    } else {
      setStakeSuccessDisplay(false);
    }
  };
  const unStakeFlag = (val: boolean): void => {
    //console.log("display msg staking" +val)

    setStake(!val);
    setwithdraw(!val);
    setUnStake(val);
    if (!stakeMsgDisplay && val && !transfailureStatus) {
      setStakeSuccessDisplay(true);
    } else {
      setStakeSuccessDisplay(false);
    }
  };

  const withDrawRewardFlag = (val: boolean): void => {
    setStake(!val);
    setUnStake(!val);
    setwithdraw(val);
    if (!stakeMsgDisplay && val && !transfailureStatus) {
      setStakeSuccessDisplay(true);
    } else {
      setStakeSuccessDisplay(false);
    }
  };

  const getTokenBalance = async () => {
    let response2 = await connection.getTokenAccountsByOwner(
      new PublicKey("DsZNdyMaHzTMHZTd1rY2ckBzQdLMFLPjkcyLxQgaYsvZ"), // owner here
      {
        mint: new PublicKey("GkywroLpkvYQc5dmFfd2RchVYycXZdaA5Uzix42iJdNo"),
      }
    );
    response2.value.forEach((e) => {
      const accountInfo = SPLToken.AccountLayout.decode(e.account.data);

      setVaultTokenDroidBalance(
        Number(SPLToken.u64.fromBuffer(accountInfo.amount)) / Math.pow(10, 9)
      );
    });
  };

  useEffect(() => {
    getTokenBalance();

    localStorage.setItem("stackingDurationIndex", "0");
    getRewardCalculator(connection).then((r) => {
      let total_stacked_nft_s =
        Number(r.staked_1) +
        Number(r.staked_2) +
        Number(r.staked_3) +
        Number(r.staked_4) +
        Number(r.staked_5);
      setoverAllStackedNft(total_stacked_nft_s);

      let week1 = Math.round((Number(r.staked_1) / total_stacked_nft_s) * 100);
      let week2 = Math.round((Number(r.staked_2) / total_stacked_nft_s) * 100);
      let week3 = Math.round((Number(r.staked_3) / total_stacked_nft_s) * 100);
      let week4 = Math.round((Number(r.staked_4) / total_stacked_nft_s) * 100);
      let week5 = Math.round((Number(r.staked_5) / total_stacked_nft_s) * 100);

      let d = [
        { label: week1 + "%", value: week1 },
        { label: week2 + "%", value: week2 },
        { label: week3 + "%", value: week3 },
        { label: week4 + "%", value: week4 },
        { label: week5 + "%", value: week5 },
      ];

      console.log(d);
      setData(d);

      setRewardCalculator(r);
    });
  }, [connection]);
  const totalRewards = (count: any): void => {
    rewardsCount = rewardsCount + parseInt(count);
  };

  useEffect(() => {
    let d_r = 0;
    stakedNfts.map((nft, index) => {
      let rank = moonRank.find((item) => nft.mint.toString() === item.mint);

      if (rank !== undefined && rank["rank"] !== undefined) {
        stakedNfts[index]["rank"] = rank["rank"];
      } else {
        stakedNfts[index]["rank"] = "";
      }

      let l_p = secondsToDhms(Number(nft.duration));

      if (l_p / 7 === 1) {
        d_r += 2;
      } else if (l_p / 7 === 4) {
        d_r += 6;
      } else if (l_p / 7 === 12) {
        d_r += 9;
      } else if (l_p / 7 === 26) {
        d_r += 15;
      } else if (l_p / 7 === 52) {
        d_r += 30;
      }
      if (index < stakedNfts.length) {
        totalRewards(
          calculateRewards(nft as StakedData, rewardDate, rewardCalculator)
        );
      }
    });
    setDailyDroidRate(d_r);
    setTotalrewardCount(rewardsCount);
    rewardsCount = 0;
  }, [wallet, stakedNfts]);

  function secondsToDhms(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600 * 24));
    // var h = Math.floor((seconds % (3600 * 24)) / 3600);
    // var m = Math.floor((seconds % 3600) / 60);
    // var s = Math.floor(seconds % 60);

    var dDisplay = d > 0 ? d : 0;

    return dDisplay;
  }

  useEffect(() => {
    let temp = [];
    let temp1 = [];
    unstakedNfts.map((nft, index) => {
      if (index < unstakedNfts.length) {
        let rank = moonRank.find((item) => nft.mint.toString() === item.mint);
        if (rank !== undefined && rank["rank"] !== undefined) {
          unstakedNfts[index]["rank"] = rank["rank"];
        } else {
          unstakedNfts[index]["rank"] = "";
        }

        let temp_json = {};
        temp_json["id"] = index;
        temp_json["address"] = nft.mint;
        temp_json["name"] = nft.json.name;
        temp_json["image"] = nft.json.image;
        temp_json["description"] = nft.json.description;

        temp_json["selected"] = false;
        temp.push(temp_json);
        temp1.push(nft.mint.toString());
      }
    });
    setunstakedNftsForTable(temp);
    setunstakedNftsForRoyalty(temp1);
  }, [wallet, unstakedNfts]);

  // useEffect(() => {
  //   console.log(unstakedNftsForTable)
  // },[unstakedNftsForTable]);

  const handleCloseStakingModal = () => {
    setStakingModal(false);
  };
  const handleCloseUnStakingModal = () => {
    setUnStakingModal(false);
  };

  // Select/ UnSelect Table rows
  const onMasterCheck = (e) => {
    unstakedNftsForTable.map((n) => (n.selected = e.target.checked));

    //Update State
  };

  const onItemCheck = async (e, item) => {
    let tempList = unstakedNftsForTable;
    tempList.map((n) => {
      if (n.id === item.id) {
        n.selected = !n.selected;
      }
      return n;
    });

    await setunstakedNftsForTable(tempList);
  };

  const sendAndConfirmMultipleTransactions = useCallback(
    async (transaction) => {
      try {
        if (transfailureStatus) {
          transFailure(false);
        }
        // console.log(getAllTokens(connection, publicKey));
        if (signAllTransactions) {
          let { blockhash } = await connection.getRecentBlockhash();
          await transaction.forEach((ta) => {
            ta.feePayer = publicKey!;
            ta.recentBlockhash = blockhash;
          });
          const signedTransactions = await signAllTransactions(transaction);

          for (const ta of signedTransactions) {
            let signature = await connection.sendRawTransaction(
              ta.serialize(),
              {
                skipPreflight: false,
              }
            );

            await connection.confirmTransaction(signature, "confirmed");
          }
          // let signature = await sendTransaction(transaction, connection);
          if (stakeMsgDisplay) setStakeMsg(true);
          setIsLoading(false);
          setStakingModal(false);
          setUnStakingModal(false);
          // await connection.confirmTransaction(signature, "confirmed");

          if (stakeMsgDisplay) setStakeMsg(false);
          if (forceRefresh) forceRefresh();
        }

        return true;
      } catch (err) {
        console.log(err);
        setStakingModal(false);
        setUnStakingModal(false);
        Swal.fire({
          title: err,
        });
        if (stakeMsgDisplay) setStakeMsg(false);
        if (stakeFlag) stakeFlag(false);
        setIsLoading(false);
        if (unStakeFlag) unStakeFlag(false);
        if (withDrawRewardFlag) withDrawRewardFlag(false);
        if (transfailureStatus) {
          transFailure(true);
        }
      }
    },
    [connection, publicKey, sendTransaction]
  );

  let setStackingDuration = async (duration) => {
    localStorage.setItem("stackingDurationIndex", duration);
    await setstackingDurationIndex(duration);
  };

  const stakeSelectedToken = useCallback(
    async (selectedToken) => {
      if (!publicKey) return;
      setIsLoading(true);
      let arr: PublicKey[] = [];

      selectedToken.forEach((element) => {
        if (element.selected === true) {
          arr.push(element.address);
        }
      });

      let time_index = Number(localStorage.getItem("stackingDurationIndex"));

      if (arr.length === 0) {
        Swal.fire({
          title: "You have not selected NFT's",
        });
        setIsLoading(false);
        return;
      }
      const res = await sendAndConfirmMultipleTransactions(
        await createStakeTokenTransaction(
          connection,
          publicKey,
          time_index,
          arr
        )
      );

      if (res) {
        Swal.fire({
          title: "You have successfully staked NFT's",
        });
        setIsLoading(false);
        stakeFlag(true);
      }

      //  setShowStakeMsg(false);
      //  console.log("stake token after transaction:"+ showStakeMsg)
    },
    [connection, publicKey, sendAndConfirmTransaction]
  );

  let checkIfPenalityApplied = async (stacked_nft) => {
    setIsLoading(true);
    let res = await getStakedDataByOwner(connection, publicKey);
    let penality_arr = [];
    let un_penality_arr = [];
    let unstake_arr: PublicKey[] = [];

    res.forEach((element) => {
      let t_nft_data = {};
      let utc_now = Math.floor(new Date().getTime() / 1000);
      let diff = utc_now - Number(element.timestamp);
      let p_diff = Number(element.duration) - diff;
      let penality_fee = 0;
      // Reward Calculation
      let r_t,
        reward = 0;
      reward = Math.floor(diff / 86400) * Number(element.rate);

      reward -= Number(element.withdrawn);
      r_t =
        BigInt(Math.floor(reward)) / BigInt(Math.pow(10, reward_token_decimal));

      t_nft_data["reward"] = r_t.toString();
      t_nft_data["name"] = element.data.name;
      t_nft_data["image"] = element.json.image;
      t_nft_data["description"] = element.json.description;

      unstake_arr.push(element.mint);
      if (Number(element.duration) > diff) {
        if (p_diff > 0 && p_diff < penalty_duration_1) {
          penality_fee = penalty_fees_1;
        } else if (p_diff > penalty_duration_1 && p_diff < penalty_duration_2) {
          penality_fee = penalty_fees_2;
        } else if (p_diff > penalty_duration_2 && p_diff < penalty_duration_3) {
          penality_fee = penalty_fees_3;
        } else if (p_diff > penalty_duration_3 && p_diff < penalty_duration_4) {
          penality_fee = penalty_fees_4;
        }

        let nft_stacked_period = staked_duration.indexOf(
          Number(element.duration)
        );

        let p_a =
          BigInt(Math.floor(penality_fee)) /
          BigInt(Math.pow(10, reward_token_decimal));
        let r_p_a = Math.abs(Number(r_t - p_a));

        t_nft_data["penality_amt"] = p_a.toString();
        t_nft_data["penality_pay_amt"] = r_p_a.toString();

        if (nft_stacked_period !== 0 && nft_stacked_period !== 1) {
          penality_arr.push(t_nft_data);
        }
      } else {
        let nft_stacked_period = staked_duration.indexOf(
          Number(element.duration)
        );

        un_penality_arr.push(t_nft_data);
      }
    });
    setnoPenalityNftForTable(un_penality_arr);
    setpenalityNftForTable(penality_arr);
    setstakedNftsAddress(unstake_arr);
    setUnStakingModal(true);
    setIsLoading(false);
    // let arr: PublicKey[] = [];
    //   stacked_nft.forEach((element) => {
    //     let stakeDataAddress = await getStakeDataAddress(element.mint);
    //     console.log(stakeDataAddress)
    //       arr.push(element.mint);
    //   });
  };

  let getData = async (mint) => {};

  const unstakeAllStackedNftToken = useCallback(
    async (arr) => {
      if (!publicKey) return;
      setIsLoading(true);

      const unstakeRes = await sendAndConfirmMultipleTransactions(
        await createUnstakeTokenTransaction(connection, publicKey, arr)
      );
      if (unStakeFlag && unstakeRes) {
        setUnStakingModal(false);
        setIsLoading(false);
        Swal.fire({
          title: "You have successfully unstaked the NFT's",
        });
        unStakeFlag(true);
      }
    },
    [connection, publicKey, sendAndConfirmTransaction]
  );

  const withdrawAllStackedNftRewards = useCallback(
    async (stacked_nft) => {
      if (!publicKey) return;
      setIsLoading(true);
      let arr: PublicKey[] = [];
      stacked_nft.forEach((element) => {
        arr.push(element.mint);
      });

      const withDrawres = await sendAndConfirmMultipleTransactions(
        await createWithdrawRewardTransaction(connection, publicKey, arr)
      );
      if (withDrawRewardFlag && withDrawres) {
        Swal.fire({
          title: "You have successfully withdrawn the rewards",
        });
        setIsLoading(false);
        withDrawRewardFlag(true);
      }
    },
    [connection, publicKey, sendAndConfirmTransaction]
  );

  const setLoading = (val: boolean): void => {
    //console.log("display msg staking" +val)
    setIsLoading(val);
  };

  const adminUnstakeToken = useCallback(
    async (m_address, nft_owner) => {
      if (!publicKey) return;
      if (m_address === "" || m_address === null) {
        Swal.fire({
          title: "NFT Address can't be null",
          confirmButtonText: "Close",
        });
        return;
      }
      if (nft_owner === "" || nft_owner === null) {
        Swal.fire({
          title: "Owner of NFT can't be null",
          confirmButtonText: "Close",
        });
        return;
      }
      setIsLoading(true);

      let arr: PublicKey[] = [new PublicKey(m_address)];
      const unstakeRes = await sendAndConfirmMultipleTransactions(
        // await createAdminUnstakeTokenTransaction(connection, publicKey, arr)
        await createAdminUnstakeTokenTransaction(
          connection,
          new PublicKey(nft_owner),
          arr
        )
      );
      if (unStakeFlag && unstakeRes) {
        setIsLoading(false);
        Swal.fire({
          title: "You have successfully unstaked the NFT's",
        });
        unStakeFlag(true);
      }
    },
    [connection, publicKey, sendAndConfirmTransaction]
  );

  // useEffect(() => {
  //   regenerateData();
  // }, []);

  // function regenerateData() {
  //   let d = [];
  //   d = [
  //     { label: "15%", value: 15 },
  //     { label: "10%", value: 10 },
  //     { label: "5%", value: 5 },
  //     { label: "5%", value: 5 },
  //     { label: "65%", value: 65 },
  //   ];
  //   setData(d);
  // }

  let checkIfRoyaltiesDue = async () => {
    let temp = unstakedNftsForTable;
    let temp1 = [];
    console.log(unstakedNftsForRoyalty);

    try {
      setIsLoading(true);
      let req_data = {
        mints: unstakedNftsForRoyalty,
        tx_types: ["NFT_SALE"],
      };
      const response = await fetch(
        "https://api.niftybot.art/nft/royalty_status_multiple_nfts?summarized=false&cutoff=1681387200",
        {
          method: "POST",
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
          },
          mode: "cors",
          body: JSON.stringify(req_data),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        data.forEach((r_nft) => {
          if (r_nft["last_sale_due"] > 0) {
            const foundObject = temp.find(
              (item) => item.address.toString() === r_nft.mint
            );
            foundObject["last_sale_due"] = r_nft["last_sale_due"];
            foundObject["seller_fee_basis_points"] =
              r_nft["metadata"]["seller_fee_basis_points"];
            foundObject["uri"] = r_nft["metadata"]["uri"];
            temp1.push(foundObject);
            temp = temp.filter(
              (item) => item.address.toString() !== r_nft.mint
            );
          }
        });
        setRoyaltyDueNfts(temp1);
        setunstakedNftsForTable(temp);
        setIsLoading(false);
        setStakingModal(true);
      } else {
        setIsLoading(false);
        Swal.fire({
          title: "Radar API is down, Please try again after some time",
        });
      }
    } catch (e) {
      setIsLoading(false);
      console.log(e);
      Swal.fire({
        title: "Radar API is down, Please try again after some time",
      });
    }
  };

  const repayRoyalties = async (
    mint,
    dueRoyalties,
    sellerFeeBasisPoints,
    metadataUri
  ) => {
    setIsLoading(true);
    try {
      // const connection = new Connection(PUBLIC_RPC_URL, "confirmed");
      let metadata = await Metadata.load(
        connection,
        await Metadata.getPDA(mint)
      );
      console.log(metadataUri);
      // const nft = await fetch(metadataUri).then((e) => e.json());
      const nft = metadata["data"]["data"];
      //   const nft = await metaplex.nfts().findByMint({ mintAddress: mint });
      if (nft) {
        console.log("Got Metadata", nft);
      } else {
        throw new Error("Unable to find nft");
      }
      // const lastValidBlockHeight = (await connection.getRecentBlockhash('max')).lastValidBlockHeight;
      const blockhashAndFee = await connection.getRecentBlockhash("max");
      // const lastValidBlockHeight = blockhashAndFee.context.slot;
      const blockhash = blockhashAndFee.blockhash;

      const transaction = new Transaction({
        feePayer: publicKey!,
        recentBlockhash: blockhash,
        // lastValidBlockHeight: lastValidBlockHeight
      });

      transaction.add(
        SystemProgram.transfer({
          fromPubkey: publicKey!,
          toPubkey: mint,
          lamports: 0,
        })
      );

      const lamportDueAmount = dueRoyalties * LAMPORTS_PER_SOL;
      console.log("Lampooorting", lamportDueAmount);

      nft.creators.forEach((creator) => {
        console.log(creator.address.toString(), creator.share / 100);
        console.log(
          "I will transfer " +
            lamportDueAmount * (creator.share / 100) +
            " Lamports to " +
            creator.address
        );
        const royaltyAmount = lamportDueAmount * (creator.share / 100);

        transaction.add(
          SystemProgram.transfer({
            fromPubkey: publicKey!,
            toPubkey: new PublicKey(creator.address),
            lamports: Math.round(royaltyAmount) + 1,
          })
        );
      });

      transaction.add(
        new TransactionInstruction({
          keys: [
            {
              pubkey: publicKey!,
              isSigner: true,
              isWritable: true,
            },
          ],
          data: Buffer.from(
            `[royalty-payment, mint:${mint}, amount:{dueRoyalties}]`,
            "utf-8"
          ),
          programId: new PublicKey(
            "MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr"
          ),
        })
      );

      console.log(transaction.instructions);

      console.log(connection);
      const transferSignature = await sendTransaction(transaction, connection, {
        skipPreflight: false,
      });

      console.log(transferSignature);
      setIsLoading(false);
      Swal.fire({
        title:
          "Congrats, you have successfully paid the due royalties. You can now click 'Stake' and receive regular $DROID emissions. Note: it can take a moment of two for the transaction to register on the blockchain.",
      });
      return transferSignature;
    } catch (err) {
      setIsLoading(false);
    }
  };

  const stakeToken = useCallback(
    async (address: PublicKey, locking_period_index) => {
      if (!publicKey) return;
      setIsLoading(true);
      let arr: PublicKey[] = [address];

      const res = await sendAndConfirmMultipleTransactions(
        await createStakeTokenTransaction(
          connection,
          publicKey,
          locking_period_index,
          arr
        )
      );

      if (stakeFlag && res) {
        forceRefresh();
        Swal.fire({
          title: "You have successfully staked NFT",
        });
        stakeFlag(true);
      }

      //  setShowStakeMsg(false);
      //  console.log("stake token after transaction:"+ showStakeMsg)
    },
    [connection, publicKey, sendAndConfirmTransaction]
  );

  return (
    <>
      {isLoading && <Loader />}
      <Modal
        show={StakingModal}
        onHide={handleCloseStakingModal}
        backdrop="static"
        className="twitter-link-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Select NFT's to stake</Modal.Title>
        </Modal.Header>
        <Modal.Body className="stake-modal-body">
          {unstakedNftsForTable.length > 0 && (
            <>
              <div className="row g-3 g-lg-6 table-body">
                <div className="col-lg-12">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            // checked={this.state.MasterChecked}
                            id="mastercheck"
                            onChange={(e) => onMasterCheck(e)}
                          />
                        </th>
                        <th scope="col">Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      {unstakedNftsForTable.map((unstackednft) => (
                        <tr className={unstackednft.selected ? "selected" : ""}>
                          <th scope="row">
                            <input
                              type="checkbox"
                              checked={unstackednft.selected ? true : false}
                              className="form-check-input"
                              id="rowcheck{user.id}"
                              onChange={(e) => onItemCheck(e, unstackednft)}
                              value={unstackednft.selected}
                            />
                          </th>
                          <td>
                            <img
                              className="nft-image-on-modal"
                              src={unstackednft.image}
                              alt={unstackednft.description}
                            />{" "}
                            {unstackednft.name}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="row g-3 g-lg-6 mt-3">
                <div className="col-lg-12">
                  <label className="d-flex align-items-center fs-6 fw-semibold mb-2 modal-label">
                    <span className="required">Staking duration</span>
                  </label>
                  <select
                    className="form-select form-select-solid"
                    onChange={(e) => setStackingDuration(e.target.value)}
                  >
                    {/* <option value="seller">Seller</option> */}

                    <option value="0">1 week</option>
                    <option value="1">4 weeks</option>
                    <option value="2">12 weeks</option>
                    <option value="3">26 weeks</option>
                    <option value="4">52 weeks</option>
                  </select>
                </div>
              </div>
            </>
          )}
          {royaltyDueNfts.length > 0 && (
            <div className="row g-3 g-lg-6 mt-3">
              <div className="col-lg-12">
                <label className="d-flex align-items-center fs-6 fw-semibold mb-2 modal-label">
                  <span className="required">
                    Below 👇 NFTs has royalty Due and you can't perform multiple
                    stake options
                  </span>
                </label>
                <label className="d-flex align-items-center fs-6 fw-semibold mb-2 modal-label">
                  <span className="required">
                    You can either pay royalties and get complete reward access
                    or you can stake it with less 20% of rewards by performing
                    individual stake option.
                  </span>
                </label>

                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col"></th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {royaltyDueNfts.map((royaltyduenft) => (
                      <tr>
                        <td>
                          <img
                            className="nft-image-on-modal"
                            src={royaltyduenft?.image}
                            alt={royaltyduenft?.description}
                          />{" "}
                          {royaltyduenft?.name}
                        </td>
                        <td>
                          <Button
                            className="stake-btn"
                            onClick={() =>
                              repayRoyalties(
                                new PublicKey(royaltyduenft?.address),
                                royaltyduenft?.last_sale_due,
                                royaltyduenft?.seller_fee_basis_points,
                                royaltyduenft?.uri
                              )
                            }
                          >
                            Pay Royalty
                          </Button>
                        </td>
                        <td>
                          <Button
                            className="stake-emission"
                            onClick={() =>
                              stakeToken(
                                new PublicKey(royaltyduenft?.address),
                                5
                              )
                            }
                          >
                            Stake (20% emission)
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </Modal.Body>

        <Modal.Footer>
          <div className="text-center mt-9">
            <button
              type="button"
              className="btn modal-btn-cancel me-3"
              onClick={handleCloseStakingModal}
            >
              Cancel
            </button>
            {unstakedNftsForTable.length > 0 && (
              <button
                onClick={() => stakeSelectedToken(unstakedNftsForTable)}
                type="button"
                className="btn modal-btn-submit stake-modal-btn"
                data-kt-modal-action-type="submit"
              >
                <span className="indicator-label">Stake</span>
              </button>
            )}
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        show={UnStakingModal}
        onHide={handleCloseUnStakingModal}
        dialogClassName="modal-90w"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Unstake All</Modal.Title>
        </Modal.Header>
        <Modal.Body className="stake-modal-body">
          <div className="row g-3 g-lg-6 table-body">
            <div className="col-lg-12">
              {noPenalityNftForTable.length > 0 && (
                <>
                  <div className="modal-sub-heading">
                    You can withdraw and unstake below nft's
                  </div>
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">NFT Name</th>
                        <th scope="col">Rewared Earned</th>
                      </tr>
                    </thead>
                    <tbody>
                      {noPenalityNftForTable.map((p_nft) => (
                        <tr>
                          <td>
                            <img
                              className="nft-image-on-modal"
                              src={p_nft.image}
                              alt={p_nft.description}
                            />
                            {p_nft.name}
                          </td>
                          <td>{p_nft.reward} $DROID</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              )}
              {noPenalityNftForTable.length === 0 && (
                <div className="modal-sub-heading">
                  You have no NFTs available to unstake
                </div>
              )}
              {penalityNftForTable.length > 0 && (
                <>
                  <div>
                    The below NFTs have not completed your chosen staking
                    period. You can choose to pay a fee in $DROID to unstake
                    them.
                  </div>
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">NFT Name</th>
                        <th scope="col">Reward Earned</th>
                        <th scope="col">Fee to Unstake</th>
                        <th scope="col">$DROID Payment (Reward - Fee)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {penalityNftForTable.map((p_nft) => (
                        <tr>
                          <td>{p_nft.name}</td>
                          <td>{p_nft.reward} $DROID</td>
                          <td>{p_nft.penality_amt} $DROID</td>
                          <td>{p_nft.penality_pay_amt} $DROID</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              )}
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="text-center mt-9">
            <button
              type="button"
              className="btn modal-btn-cancel me-3"
              onClick={handleCloseUnStakingModal}
            >
              Cancel
            </button>
            {noPenalityNftForTable.length !== 0 && (
              <button
                onClick={() => unstakeAllStackedNftToken(stakedNftsAddress)}
                type="button"
                className="btn modal-btn-submit unstake-modal-btn"
                data-kt-modal-action-type="submit"
              >
                <span className="indicator-label">Un Stake</span>
              </button>
            )}
          </div>
        </Modal.Footer>
      </Modal>
      {wallet && provider && provider.isConnected ? (
        <Navbar collapseOnSelect expand="lg" bg="transparent" variant="dark">
          <Container className="header-menu-container">
            <Navbar.Brand href="#home">
              <div className="logo">
                <img src={logo}></img>
              </div>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto"></Nav>
              <Nav className="nav-social-container">
                {/* <Nav.Link href="https://lostsols.club/search.php" target="_blank">Rarity Tool</Nav.Link> */}
                {!wallet && (
                  <Nav.Link
                    href="https://twitter.com/droidcapital"
                    target="_blank"
                    className="nav-social"
                  >
                    <img src={menu_twitter} />
                  </Nav.Link>
                )}
                {!wallet && (
                  <Nav.Link
                    href="https://discord.gg/8MBfWGt5VH"
                    target="_blank"
                    className="nav-social"
                  >
                    <img src={menu_discord} />
                  </Nav.Link>
                )}
                {wallet ? (
                  <Nav.Item>
                    <WalletDisconnectButton className="mx-auto btn disconnect-btn" />
                  </Nav.Item>
                ) : null}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      ) : (
        ""
      )}

      {wallet && provider && provider.isConnected ? (
        <>
          {publicKey.toString() !==
          "8Hk1jJzPrYF566hZoCP4fvaVhjdxuNZ3Qo7ytMxyBq3H" ? (
            <Container className="pl-5 pr-5 pb-5 logged-in-container">
              <Row className="text-center">
                <Col>
                  <div className="row countStyle">
                    <div className="column">
                      <Row>
                        <Col md={2} className="mx-a">
                          <img width="50" src={droid_icon_1} />
                        </Col>
                        <Col md={10} className="count-col-right">
                          <div className="text-left">
                            <div className="text-cream">
                              {stakedNfts.length}
                            </div>
                            <div className="count-col-text">
                              Total Number of Your NFT Staked
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="column">
                      <Row>
                        <Col md={2} className="mx-a">
                          <img width="50" src={droid_icon_3} />
                        </Col>
                        <Col md={10} className="count-col-right">
                          <div className="text-left">
                            <div className="text-cream">{totalrewardCount}</div>
                            <div className="count-col-text">
                              Total $DROID You Accumulated
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="column">
                      <Row>
                        <Col md={2} className="mx-a">
                          <img width="50" src={droid_icon_2} />
                        </Col>
                        <Col md={10} className="count-col-right">
                          <div className="text-left">
                            <div className="text-cream">{dailyDroidRate}</div>
                            <div className="count-col-text">
                              Daily $DROID rate
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col className="col-left-cont" xs={12} md={8} sm={12}>
                  <Tabs
                    onSelect={(index, label) =>
                      console.log(label + " selected")
                    }
                  >
                    <Tab label="Your Staked NFTs">
                      <Row className="">
                        <Col xs={12} md={5} sm={12}></Col>
                        <Col xs={12} md={3} sm={12}>
                          {stakedNfts.length > 0 && (
                            <Button
                              className="unstake-all-btn mt-2"
                              onClick={() => checkIfPenalityApplied(stakedNfts)}
                            >
                              Unstake All
                            </Button>
                          )}
                        </Col>
                        <Col xs={12} md={3} sm={12}>
                          {stakedNfts.length > 0 && (
                            <Button
                              className="withdraw-all-btn mt-2"
                              onClick={() =>
                                withdrawAllStackedNftRewards(stakedNfts)
                              }
                            >
                              Withdraw all reward
                            </Button>
                          )}
                        </Col>
                        <Col xs={12} md={1} sm={12}></Col>
                      </Row>
                      <Row style={{ paddingTop: "12px" }} className="">
                        {stakedNfts.map((nft) => (
                          <Col xs={12} md={3} sm={12} className="mb-5">
                            <NFT
                              key={nft.json.name}
                              nft={nft}
                              duration={nft.duration}
                              onChange={forceRefresh}
                              staked={true}
                              stakecount={stakedNfts.length}
                              rewardCalculator={rewardCalculator}
                              msg={setStakeMsg}
                              isloading={setLoading}
                              stakeFlag={stakeFlag}
                              unStakeFlag={unStakeFlag}
                              withDrawRewardFlag={withDrawRewardFlag}
                              transfailure={transFailure}
                              totalRewards={totalRewards}
                            />
                          </Col>
                        ))}
                      </Row>
                    </Tab>
                    <Tab label="Your Unstaked NFTs">
                      <Row className="">
                        <Col xs={12} md={9} sm={12}></Col>

                        <Col xs={12} md={3} sm={12}>
                          {unstakedNfts.length > 0 && (
                            <Button
                              className="stake-all-btn mt-2"
                              onClick={() => checkIfRoyaltiesDue()}
                            >
                              Stake Multiple
                            </Button>
                          )}
                        </Col>
                      </Row>

                      <Row style={{ paddingTop: "12px" }} className="">
                        {/* {isInnerLoading && <div className="inner-loading-spinner"></div>} */}
                        {unstakedNfts.map((nft) => (
                          <Col xs={12} md={3} sm={12} className="mb-5">
                            <NFT
                              key={nft.json.name}
                              nft={nft}
                              duration=""
                              onChange={forceRefresh}
                              staked={false}
                              stakecount={stakedNfts.length}
                              msg={setStakeMsg}
                              isloading={setLoading}
                              stakeFlag={stakeFlag}
                              unStakeFlag={unStakeFlag}
                              withDrawRewardFlag={withDrawRewardFlag}
                              transfailure={transFailure}
                              totalRewards={totalRewards}
                            />
                          </Col>
                        ))}
                      </Row>
                    </Tab>
                  </Tabs>
                </Col>
                <Col className="col-right-cont" xs={12} md={4} sm={12}>
                  <Tabs
                    className="right-side-tab"
                    onSelect={(index, label) =>
                      console.log(label + " selected")
                    }
                  >
                    <Tab label="Staking Rules">
                      <table className="table-2 mt-3">
                        <tbody>
                          <tr>
                            <td className="td-w-70">Minimum Staking Period</td>
                            <td className="td-w-30">1 Day</td>
                          </tr>
                          <tr>
                            <td className="td-w-70">
                              Minimum Reward Increments{" "}
                            </td>
                            <td className="td-w-30">1 Day</td>
                          </tr>
                        </tbody>
                      </table>
                      <div className="caption">Locking Period Rewards</div>
                      <table className="table-2">
                        <tbody>
                          <tr>
                            <td>1 Week</td>
                            <td>2 $DROID per day</td>
                          </tr>
                          <tr>
                            <td>4 Week</td>
                            <td>6 $DROID per day</td>
                          </tr>
                          <tr>
                            <td>12 Week</td>
                            <td>9 $DROID per day</td>
                          </tr>
                          <tr>
                            <td>26 Week</td>
                            <td>15 $DROID per day</td>
                          </tr>
                          <tr>
                            <td>52 Week</td>
                            <td>30 $DROID per day</td>
                          </tr>
                        </tbody>
                      </table>
                      <div className="caption">Payments to Unlock</div>
                      <div className="caption-2">Unstake Early w $DROID</div>
                      <table className="table-2">
                        <colgroup>
                          <col span={1} style={{ width: "30%" }}  />
                          <col span={1} style={{ width: "10%" }}  />
                          <col span={1} style={{ width: "60%" }}  />
                        </colgroup>
                        <tbody>
                          <tr>
                            <td>{"<"} 4 weeks</td>
                            <td>=</td>
                            <td>No early unlock</td>
                          </tr>
                          <tr>
                            <td>{"<"} 1 month</td>
                            <td>=</td>
                            <td>1,250 $DROID</td>
                          </tr>
                          <tr>
                            <td>{"<"} 3 month</td>
                            <td>=</td>
                            <td>2,500 $DROID</td>
                          </tr>
                          <tr>
                            <td>{"<"} 6 month</td>
                            <td>=</td>
                            <td>5,000 $DROID</td>
                          </tr>
                          <tr>
                            <td>{"<"} 12 month</td>
                            <td>=</td>
                            <td>10,000 $DROID</td>
                          </tr>
                        </tbody>
                        {/* <tbody>
                          <tr>
                            <td>1 Week & 4 Week locks are fixed</td>
                          </tr>
                          <tr>
                            <td>
                              Less than 1 month remaining 600 $DROID to unlock
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Less than 3 month remaining 1200 $DROID to unlock
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Less than 6 month remaining 2500 $DROID to unlock
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Less than 12 month remaining 5000 $DROID to unlock
                            </td>
                          </tr>
                        </tbody> */}
                      </table>
                    </Tab>
                    <Tab label="Statistics">
                      <Row>
                        <Col xs={12} md={8} sm={12}>
                          <PieChart
                            data={data}
                            outerRadius={100}
                            innerRadius={0}
                          />
                        </Col>
                        <Col xs={12} md={4} sm={12}>
                          <div className="rating">
                            <div>
                              <span id="week-1"></span>{" "}
                              <span className="week-text"> 1 Week</span>
                            </div>
                            <div>
                              <span id="week-2"></span>{" "}
                              <span className="week-text">4 Week</span>
                            </div>
                            <div>
                              <span id="week-3"></span>{" "}
                              <span className="week-text">12 Week</span>
                            </div>
                            <div>
                              <span id="week-4"></span>{" "}
                              <span className="week-text">26 Week</span>
                            </div>
                            <div>
                              <span id="week-5"></span>{" "}
                              <span className="week-text">52 Week</span>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row className="droid-stcked-container text-center mb-2">
                        <Col md={12} className="mx-auto">
                          <div className="droid-staked-per-inside pb-2">
                            {overAllStackedNft} / 6350 NFT staked
                          </div>
                          <div className="progress h-5px w-100 col-md-auto">
                            <div
                              className="progress-bar bg-success"
                              style={{
                                width: (overAllStackedNft / 6350) * 100 + "%",
                              }}
                            ></div>
                          </div>
                        </Col>
                      </Row>
                    </Tab>
                    <Tab label="FAQ">
                      <Row>
                        <Col>
                          <div className="faq-articles">
                            <article className="faq-accordion">
                              <input
                                type="checkbox"
                                className="tgg-title"
                                id="tgg-title-1"
                              />

                              <div className="faq-accordion-title">
                                <label htmlFor="tgg-title-1">
                                  <h2>
                                    {" "}
                                    I can't see my Droids on the staking site.
                                    What do I do ?
                                  </h2>
                                  <span className="arrow-icon">
                                    <img src={down_arrow_svg} />
                                  </span>
                                </label>
                              </div>

                              <div className="faq-accordion-content">
                                <p>
                                  Using an VPN can fix this issues quickly. We
                                  recommend trying one of the following Chrome
                                  VPN extensions available from the official
                                  store.
                                  <br />
                                  <a
                                    target="_blank"
                                    href="https://chrome.google.com/webstore/detail/ultrasurf-security-privac/mjnbclmflcpookeapghfhapeffmpodij?hl=en"
                                  >
                                    Ultrasurf
                                  </a>
                                  <br />{" "}
                                  <a
                                    target="_blank"
                                    href="https://chrome.google.com/webstore/detail/setupvpn-lifetime-free-vp/oofgbpoabipfcfjapgnbbjjaenockbdp/related"
                                  >
                                    SetupVP
                                  </a>
                                </p>
                              </div>
                            </article>
                            <article className="faq-accordion">
                              <input
                                type="checkbox"
                                className="tgg-title"
                                id="tgg-title-2"
                              />

                              <div className="faq-accordion-title">
                                <label htmlFor="tgg-title-2">
                                  <h2> Why is the VPN needed ?</h2>
                                  <span className="arrow-icon">
                                    <img src={down_arrow_svg} />
                                  </span>
                                </label>
                              </div>

                              <div className="faq-accordion-content">
                                <p>
                                  On occasions, your current network might not
                                  allow Arweave / Metaplex URL to fetch your NFT
                                  data. This is not an issue related to your
                                  location OR the staking platform. We recommend
                                  to try using a VPN - a free Chrome extension
                                  VPN will suffice - to solve this issue.
                                </p>
                              </div>
                            </article>

                            <article className="faq-accordion">
                              <input
                                type="checkbox"
                                className="tgg-title"
                                id="tgg-title-3"
                              />

                              <div className="faq-accordion-title">
                                <label htmlFor="tgg-title-3">
                                  <h2>Can i bulk unstake the NFT?</h2>
                                  <span className="arrow-icon">
                                    <img src={down_arrow_svg} />
                                  </span>
                                </label>
                              </div>

                              <div className="faq-accordion-content">
                                <p>
                                It is not possible to bulk unstake NFTs you own before the committed locking period has been reached until AFTER you have paid the $DROID penalty for doing so.
                                </p>
                              </div>
                            </article>

                            <article className="faq-accordion">
                              <input
                                className="tgg-title"
                                type="checkbox"
                                id="tgg-title-4"
                              />

                              <div className="faq-accordion-title">
                                <label htmlFor="tgg-title-4">
                                  <h2>
                                    I dont want to unstake all but I want to
                                    unstake 'many' how do I do this?
                                  </h2>
                                  <span className="arrow-icon">
                                    <img src={down_arrow_svg} />
                                  </span>
                                </label>
                              </div>

                              <div className="faq-accordion-content">
                                <p>
                                  After clicking unstake all, a window will
                                  appear where you can choose using a ticket box
                                  which NFTs you want to unstake.
                                </p>
                              </div>
                            </article>
                            <article className="faq-accordion">
                              <input
                                className="tgg-title"
                                type="checkbox"
                                id="tgg-title-5"
                              />

                              <div className="faq-accordion-title">
                                <label htmlFor="tgg-title-5">
                                  <h2>
                                    How do I clear the cache and refresh the
                                    site if I am having problems:{" "}
                                  </h2>
                                  <span className="arrow-icon">
                                    <img src={down_arrow_svg} />
                                  </span>
                                </label>
                              </div>

                              <div className="faq-accordion-content">
                                <p>
                                  Hold down Ctrl and click the Reload button.
                                </p>
                              </div>
                            </article>
                          </div>
                        </Col>
                      </Row>
                    </Tab>
                  </Tabs>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12} sm={12}>
                  <div className="stake-note">
                    Click the "Select" button under your NFTs to claim your
                    rewards or select ‘Claim All’. In some cases you are
                    eligible to pay $DROID to unlock your NFTs prior to staking
                    ending.
                    <br />
                    Note: It may take up to 30 seconds to load your NFTs once
                    you have connected your wallet
                  </div>
                </Col>
                <Col></Col>
              </Row>
            </Container>
          ) : (
            <Container className="pl-5 pr-5 pb-5">
              <Row className="">
                <Col xs={12} md={6} sm={12}>
                  <div className="row countStyle">
                    <div className="column-admin">
                      <Row>
                        <Col md={2}>
                          <img width="60" src={droid_icon_1} />
                        </Col>
                        <Col md={10} className="count-col-right">
                          <div className="text-left">
                            <div>{vaultTokenDroidBalance} $DROID</div>
                            <div className="count-col-text">
                              Amont of $DROID remaining in staking pool
                            </div>
                            <div className="count-col-text">
                              DsZNdyMaHzTMHZTd1rY2ckBzQdLMFLPjkcyLxQgaYsvZ
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div className="mx-auto admin-statistics">
                    <Row>
                      <Col xs={12} md={8} sm={12}>
                        <PieChart
                          data={data}
                          outerRadius={100}
                          innerRadius={0}
                        />
                      </Col>
                      <Col xs={12} md={4} sm={12}>
                        <div className="rating">
                          <div>
                            <span id="week-1"></span>{" "}
                            <span className="week-text"> 1 Week</span>
                          </div>
                          <div>
                            <span id="week-2"></span>{" "}
                            <span className="week-text">4 Week</span>
                          </div>
                          <div>
                            <span id="week-3"></span>{" "}
                            <span className="week-text">12 Week</span>
                          </div>
                          <div>
                            <span id="week-4"></span>{" "}
                            <span className="week-text">26 Week</span>
                          </div>
                          <div>
                            <span id="week-5"></span>{" "}
                            <span className="week-text">52 Week</span>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row className="droid-stcked-container text-center mb-2">
                      <Col md={12} className="mx-auto">
                        <div className="droid-staked-per-inside pb-2">
                          {overAllStackedNft} / 6350 NFT staked
                        </div>
                        <div className="progress h-5px w-100 col-md-auto">
                          <div
                            className="progress-bar bg-success"
                            style={{
                              width: (overAllStackedNft / 6350) * 100 + "%",
                            }}
                          ></div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col xs={12} md={6} sm={12}>
                  <form className="unstake-form">
                    <label htmlFor="test2">Choose NFT # to unlock </label>
                    <div>
                      <input
                        id="test2"
                        placeholder="Enter NFT Address"
                        type="text"
                        value={nftMintForUnstake}
                        onChange={(e) => setNftMintForUnstake(e.target.value)}
                      />
                    </div>
                    <label htmlFor="test2" className="mt-3">
                      Owner Of NFT
                    </label>
                    <div>
                      <input
                        id="test2"
                        placeholder="Enter NFT Owner Address"
                        type="text"
                        value={nftOwnerForUnstake}
                        onChange={(e) => setNftOwnerForUnstake(e.target.value)}
                      />
                    </div>
                  </form>
                  <button
                    onClick={() =>
                      adminUnstakeToken(nftMintForUnstake, nftOwnerForUnstake)
                    }
                    className="admin-un-stake"
                  >
                    UNSTAKE{" "}
                  </button>
                </Col>
              </Row>

              {/* <Row className="mt-2 text-center">
                <Col
                  xs={12}
                  md={7}
                  sm={12}
                  className="mx-auto admin-statistics"
                >
                  <Row>
                    <Col xs={12} md={8} sm={12}>
                      <PieChart data={data} outerRadius={100} innerRadius={0} />
                    </Col>
                    <Col xs={12} md={4} sm={12}>
                      <div className="rating">
                        <div>
                          <span id="week-1"></span>{" "}
                          <span className="week-text"> 1 Week</span>
                        </div>
                        <div>
                          <span id="week-2"></span>{" "}
                          <span className="week-text">4 Week</span>
                        </div>
                        <div>
                          <span id="week-3"></span>{" "}
                          <span className="week-text">12 Week</span>
                        </div>
                        <div>
                          <span id="week-4"></span>{" "}
                          <span className="week-text">26 Week</span>
                        </div>
                        <div>
                          <span id="week-5"></span>{" "}
                          <span className="week-text">52 Week</span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="droid-stcked-container text-center mb-2">
                    <Col md={12} className="mx-auto">
                      <div className="droid-staked-per-inside pb-2">
                        {overAllStackedNft} / 6400 NFT staked
                      </div>
                      <div className="progress h-5px w-100 col-md-auto">
                        <div
                          className="progress-bar bg-success"
                          style={{
                            width: (overAllStackedNft / 6400) * 100,
                          }}
                        ></div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row> */}
            </Container>
          )}
          {/* <Container className="pl-5 pr-5 pb-5">
            <Row className="text-center mt-5">
              <Col>
                <div className="headline">Droid Staking Pad!</div>
                <div className="sub-heading-1">
                  Stake your Droid NFT and Earn $DROID
                </div>
              </Col>
            </Row>

            <div className="sub-container">
              <Row className="text-center mt-5">
                <Col>
                  <div className="row countStyle">
                    <div className="column">
                      <Row>
                        <Col md={2}>
                          <img width="60" src={stack_img} />
                        </Col>
                        <Col md={10} className="count-col-right">
                          <div className="text-left">
                            <div>{stakedNfts.length}</div>
                            <div className="count-col-text">
                              Total Number of NFT Staked
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="column">
                      <Row>
                        <Col md={2}>
                          <img width="60" src={earn} />
                        </Col>
                        <Col md={10} className="count-col-right">
                          <div className="text-left">
                            <div>{totalrewardCount}</div>
                            <div className="count-col-text">
                              Total $DROID Accumulated
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
               
                  <div className="mt-4 sub-heading info-sub-heading">
                    Click The "Select" Button Under Your NFTs To Earn Rewards!
                  </div>
                  {refreshHandle ? (
                    <div className="color-white">
                      (It may take up to 30 seconds to load your NFTs once you
                      have connected your wallet)
                    </div>
                  ) : null}
                </Col>
              </Row>

              {stakeMsgDisplay ? (
                <Row className="trans">
                  <span>Transaction is in Progress. Please wait!!!</span>
                </Row>
              ) : null}
          
              <Row className="mt-5 mb-3">
                <Col>
                  <div className="c-heading">Your Staked NFTs</div>
                </Col>
                <Col md={3}>
                  {stakedNfts.length > 0 && (
                    <Button
                      className="unstake-all-btn"
                      onClick={() => checkIfPenalityApplied(stakedNfts)}
                    >
                      Unstake All
                    </Button>
                  )}
                </Col>
                <Col md={3}>
                  {stakedNfts.length > 0 && (
                    <Button
                      className="withdraw-all-btn"
                      onClick={() => withdrawAllStackedNftRewards(stakedNfts)}
                    >
                      Withdraw all reward
                    </Button>
                  )}
                </Col>
              </Row>

              <Row style={{ paddingTop: "12px" }} className="">
                {stakedNfts.map((nft) => (
                  <Col xs={12} md={3} sm={12} className="mb-5">
                    <NFT
                      key={nft.json.name}
                      nft={nft}
                      duration={nft.duration}
                      onChange={forceRefresh}
                      staked={true}
                      stakecount={stakedNfts.length}
                      rewardCalculator={rewardCalculator}
                      msg={setStakeMsg}
                      isloading={setLoading}
                      stakeFlag={stakeFlag}
                      unStakeFlag={unStakeFlag}
                      withDrawRewardFlag={withDrawRewardFlag}
                      transfailure={transFailure}
                      totalRewards={totalRewards}
                    />
                  </Col>
                ))}
              </Row>

              <Row className="mt-5 mb-3">
                <Col>
                  <div className="c-heading">Your Unstaked NFTs</div>
                </Col>
                <Col md={3}>
                  {unstakedNfts.length > 0 && (
                    <Button
                      className="stake-all-btn"
                      onClick={() => setStakingModal(true)}
                    >
                      Stake All
                    </Button>
                  )}
                </Col>
              </Row>
              <Row style={{ paddingTop: "12px" }} className="">
               
                {unstakedNfts.map((nft) => (
                  <Col xs={12} md={3} sm={12} className="mb-5">
                    <NFT
                      key={nft.json.name}
                      nft={nft}
                      duration=""
                      onChange={forceRefresh}
                      staked={false}
                      stakecount={stakedNfts.length}
                      msg={setStakeMsg}
                      isloading={setLoading}
                      stakeFlag={stakeFlag}
                      unStakeFlag={unStakeFlag}
                      withDrawRewardFlag={withDrawRewardFlag}
                      transfailure={transFailure}
                      totalRewards={totalRewards}
                    />
                  </Col>
                ))}
              </Row>
            </div>
          </Container> */}
        </>
      ) : (
        <Container className="pb-5">
          <Row className="text-center pt-5">
            <Col>
              <div>
                <img className="brand" src={logo_red_landing_page} />
              </div>
            </Col>
          </Row>
          {/* <Row className="text-center mt-5">
            <Col>
              <div className="headline">Droid Staking Pad!</div>
              <div className="sub-heading">
              Droid Capital is a cross-chain hive, HQ, and home base for ALL entrepreneurial-degen-big-brains. NFT holders become part of the exciting Droid|DAO.
              </div>
            </Col>
          </Row> */}
          <Row className=" text-center  pt-5">
            <Col md={6} className="mx-auto droid-stcked-container">
              <div className="droid-staked-per  pb-2">
                {overAllStackedNft} / 6350 NFT staked
              </div>
              <div className="progress h-8px w-100 col-md-auto">
                <div
                  className="progress-bar bg-success"
                  style={{
                    width: (overAllStackedNft / 6350) * 100 + "%",
                  }}
                ></div>
              </div>
              {/* <div className="droid-pool-balance mt-2">
                $DROID Pool balance : 6400
              </div> */}
            </Col>
          </Row>
          <Row className="pt-2">
            <Col>
              <WalletMultiButton className="home-sellect-wallet-btn mx-auto btn">
                Stake Your DROID{" "}
              </WalletMultiButton>
            </Col>
          </Row>
          {/* <Slider className="slider-container" {...settings}>
            <div>
              <img className="slider-img" src={slider1} />
            </div>
            <div>
              <img className="slider-img" src={slider2} />
            </div>
            <div>
              <img className="slider-img" src={slider3} />
            </div>
            <div>
              <img className="slider-img" src={slider4} />
            </div>
            <div>
              <img className="slider-img" src={slider4} />
            </div>
            <div>
              <img className="slider-img" src={slider4} />
            </div>
            <div>
              <img className="slider-img" src={slider4} />
            </div>
            <div>
              <img className="slider-img" src={slider4} />
            </div>
            <div>
              <img className="slider-img" src={slider4} />
            </div>
            <div>
              <img className="slider-img" src={slider4} />
            </div>
            <div>
              <img className="slider-img" src={slider4} />
            </div>
          </Slider> */}
          {/* <Button className="admin-btn" onClick={() => setStakingModal(true)}>
            Admin
          </Button> */}
          <div className="rounded-social-buttons">
            <a
              className="social-button"
              href="https://discord.gg/droid"
              target="_blank"
            >
              <img src={menu_discord} />
            </a>
            <a
              className="social-button"
              href="https://twitter.com/droidcapital"
              target="_blank"
            >
              {" "}
              <img src={menu_twitter} />
            </a>
            <a
              className="social-button"
              href="https://magiceden.io/marketplace/droid_capital"
              target="_blank"
            >
              <img src={menu_magic_eden} />
            </a>
          </div>
        </Container>
      )}

      <div className="footer py-2 d-flex flex-lg-column" id="kt_footer">
        <div className="container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between">
          <div className="text-dark order-2 order-md-1 m-auto">
            <a
              href="http://thevault-x.com"
              target="_blank"
              className="powered-by"
              rel="noreferrer"
            >
              Powered By Vault-X
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

const NFT = ({
  nft,
  duration,
  staked,
  stakecount,
  onChange,
  rewardCalculator = null,
  msg,
  isloading,
  stakeFlag,
  unStakeFlag,
  transfailure,
  withDrawRewardFlag,
  totalRewards,
}: {
  nft: UnstakedData;
  duration: any;
  staked: boolean;
  stakecount: any;
  onChange?: () => any;
  rewardCalculator?: RewardCalculator | null;
  msg?: (arg: boolean) => void;
  isloading?: (arg: boolean) => void;
  stakeFlag?: (arg: boolean) => void;
  unStakeFlag?: (arg: boolean) => void;
  withDrawRewardFlag?: (arg: boolean) => void;
  transfailure?: (arg: boolean) => void;
  totalRewards?: (arg: any) => void;
}) => {
  const { connection } = useConnection();
  const { publicKey, sendTransaction, signAllTransactions } = useWallet();

  //const [ transFailure, setTransFailure ] = useState<boolean>(false);
  const [rewardDate, setRewardDate] = useState<Date>(new Date());
  const [stakeModal, setstakeModal] = useState<boolean>(true);

  function secondsToDhms(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor((seconds % (3600 * 24)) / 3600);
    var m = Math.floor((seconds % 3600) / 60);
    var s = Math.floor(seconds % 60);

    var dDisplay = d > 0 ? d + (d == 1 ? " day, " : " days") : "";
    var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";

    return dDisplay + hDisplay + mDisplay + sDisplay;
  }

  useEffect(() => {
    if (!rewardCalculator) return;
    let { rewardPeriod } = rewardCalculator;
    let interval = Number.MAX_SAFE_INTEGER;

    if (rewardPeriod < BigInt(Number.MAX_SAFE_INTEGER)) {
      // interval = parseInt(rewardPeriod.toString()) * 1000;
      interval = 1000;
    }

    let id = setInterval(() => setRewardDate(new Date()), interval);

    return () => clearInterval(id);
  }, [rewardCalculator]);

  const sendAndConfirmMultipleTransactions = useCallback(
    async (transaction) => {
      try {
        if (transfailure) {
          transfailure(false);
        }
        // console.log(getAllTokens(connection, publicKey));
        if (signAllTransactions) {
          let { blockhash } = await connection.getRecentBlockhash();
          await transaction.forEach((ta) => {
            ta.feePayer = publicKey!;
            ta.recentBlockhash = blockhash;
          });
          const signedTransactions = await signAllTransactions(transaction);

          for (const ta of signedTransactions) {
            let signature = await connection.sendRawTransaction(
              ta.serialize(),
              {
                skipPreflight: false,
              }
            );

            await connection.confirmTransaction(signature, "confirmed");
          }
          // let signature = await sendTransaction(transaction, connection);

          if (isloading) isloading(false);

          if (msg) msg(false);
          if (onChange) onChange();
          return true;
        }

        return true;
      } catch (err) {
        if (msg) msg(false);
        if (isloading) isloading(false);
        if (stakeFlag) stakeFlag(false);
        if (unStakeFlag) unStakeFlag(false);
        if (withDrawRewardFlag) withDrawRewardFlag(false);
        if (transfailure) {
          transfailure(true);
        }
      }
    },
    [connection, publicKey, sendTransaction, onChange]
  );

  const sendAndConfirmTransaction = useCallback(
    async (transaction) => {
      try {
        if (transfailure) {
          transfailure(false);
        }

        let { blockhash } = await connection.getRecentBlockhash();
        transaction.feePayer = publicKey!;
        transaction.recentBlockhash = blockhash;

        let signature = await sendTransaction(transaction, connection, {
          skipPreflight: false,
        });
        if (msg) msg(true);
        await connection.confirmTransaction(signature, "confirmed");

        if (msg) msg(false);
        if (isloading) isloading(false);
        if (onChange) onChange();

        return signature;
      } catch (err) {
        if (msg) msg(false);
        if (isloading) isloading(false);
        if (stakeFlag) stakeFlag(false);
        if (unStakeFlag) unStakeFlag(false);
        if (withDrawRewardFlag) withDrawRewardFlag(false);
        if (transfailure) {
          transfailure(true);
        }
      }
    },
    [connection, publicKey, sendTransaction, onChange]
  );
  const stakeToken = useCallback(
    async (address: PublicKey, locking_period_index) => {
      if (!publicKey) return;
      isloading(true);
      let arr: PublicKey[] = [address];

      const res = await sendAndConfirmMultipleTransactions(
        await createStakeTokenTransaction(
          connection,
          publicKey,
          locking_period_index,
          arr
        )
      );

      if (stakeFlag && res) {
        onChange();
        Swal.fire({
          title: "You have successfully staked NFT",
        });
        stakeFlag(true);
      }

      //  setShowStakeMsg(false);
      //  console.log("stake token after transaction:"+ showStakeMsg)
    },
    [connection, publicKey, sendAndConfirmTransaction]
  );

  let penality_and_reward_before_unstake = async (mint_address) => {
    isloading(true);

    let res = await getStakedDataByOwner(connection, publicKey);
    let reward;
    let have_penality = false;
    let penality;
    let penality_to_pay;
    let current_locking_period;
    let not_unstake = false;
    res.forEach((element) => {
      if (element.mint.toString() === mint_address.toString()) {
        let utc_now = Math.floor(new Date().getTime() / 1000);

        let diff = utc_now - Number(element.timestamp);
        let p_diff = Number(element.duration) - diff;

        current_locking_period = Number(element.duration);
        console.log(Number(element.duration));
        let nft_stacked_period = staked_duration.indexOf(
          Number(element.duration)
        );
        console.log(nft_stacked_period);
        if (
          (nft_stacked_period === 0 ||
            nft_stacked_period === 1 ||
            nft_stacked_period === 5) &&
          diff < Number(element.duration)
        ) {
          not_unstake = true;
        }

        reward = 0;
        reward = Math.floor(diff / 86400) * Number(element.rate);
        reward -= Number(element.withdrawn);

        let r_t =
          BigInt(Math.floor(reward)) /
          BigInt(Math.pow(10, reward_token_decimal));

        reward = r_t.toString();
        let penality_fee = 0;
        if (Number(element.duration) > diff) {
          have_penality = true;
          if (p_diff > 0 && p_diff < penalty_duration_1) {
            penality_fee = penalty_fees_1;
          } else if (
            p_diff > penalty_duration_1 &&
            p_diff < penalty_duration_2
          ) {
            penality_fee = penalty_fees_2;
          } else if (
            p_diff > penalty_duration_2 &&
            p_diff < penalty_duration_3
          ) {
            penality_fee = penalty_fees_3;
          } else if (
            p_diff > penalty_duration_3 &&
            p_diff < penalty_duration_4
          ) {
            penality_fee = penalty_fees_4;
          }
          let p_a =
            BigInt(Math.floor(penality_fee)) /
            BigInt(Math.pow(10, reward_token_decimal));
          let r_p_a = r_t - p_a;

          penality = p_a.toString();
          penality_to_pay = r_p_a.toString();
        }
      }
    });
    if (current_locking_period === 1800 && not_unstake) {
      isloading(false);
      Swal.fire({
        title:
          "You have not pay the Royalty and locked NFT for 30 miutes. It cannot be unlocked yet.",
      });
      return;
    } else if (not_unstake) {
      current_locking_period = secondsToDhms(current_locking_period);
      isloading(false);
      Swal.fire({
        title:
          "You have chosen to lock your NFT for " +
          current_locking_period +
          " days. It cannot be unlocked yet.",
      });
      return;
    } else {
      let html =
        "<div className='popup-reward-earned'>Reward Earned :  <b>" +
        reward +
        " $DROID</b></div> ";
      if (have_penality) {
        html += "<div>Penalty :  <b>" + penality + "</b></div> ";
        html +=
          "<div>Penalty To Pay :  <b>" +
          Math.abs(penality_to_pay) +
          "</b></div> ";
      }
      isloading(false);
      Swal.fire({
        title: "Withdraw & Unstake",
        html: html,
        showCancelButton: true,
        confirmButtonText: "Unstake",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          unstakeToken(mint_address);
        }
      });
    }
  };

  const unstakeToken = useCallback(
    async (address: PublicKey) => {
      if (!publicKey) return;

      isloading(true);
      let arr: PublicKey[] = [address];

      const unstakeRes = await sendAndConfirmMultipleTransactions(
        await createUnstakeTokenTransaction(connection, publicKey, arr)
      );
      if (unStakeFlag && unstakeRes) {
        onChange();
        isloading(false);
        Swal.fire({
          title: "You have successfully unstaked the NFT",
        });
        unStakeFlag(true);
      }
    },
    [connection, publicKey, sendAndConfirmTransaction]
  );

  let upgrade = async (mint_address) => {
    isloading(true);

    let res = await getStakedDataByOwner(connection, publicKey);
    let current_locking_period;
    let next_locking_period;
    let reached_max = false;
    let not_upgrade = false;
    res.forEach((element) => {
      if (element.mint.toString() === mint_address.toString()) {
        let utc_now = Math.floor(new Date().getTime() / 1000);
        let diff = utc_now - Number(element.timestamp);

        current_locking_period = Number(element.duration);
        let nft_stacked_period = staked_duration.indexOf(
          Number(element.duration)
        );

        if (diff < Number(element.duration)) {
          not_upgrade = true;
        }
        if (nft_stacked_period === staked_duration.length - 1) {
          reached_max = true;
        } else {
          next_locking_period = staked_duration[nft_stacked_period + 1];
        }
      }
    });
    current_locking_period = secondsToDhms(current_locking_period);
    next_locking_period = secondsToDhms(next_locking_period);
    if (not_upgrade) {
      isloading(false);
      Swal.fire({
        title:
          "You have chosen to lock your NFT for " +
          current_locking_period +
          " days. It cannot be unlocked yet.",
      });
      return;
    } else if (reached_max) {
      isloading(false);
      Swal.fire({
        title:
          "This NFT locked in " +
          current_locking_period +
          " and not upgrade to more",
      });
    } else {
      let html =
        "<div className='current-locking-period-text'>Current locking period :  <b>" +
        current_locking_period +
        "</b></div> ";
      html +=
        "<div className='upgrade-locking-period-text'>Upgrade to :  <b>" +
        next_locking_period +
        "</b></div> ";
      isloading(false);
      Swal.fire({
        title: "Upgrade",
        html: html,
        showCancelButton: true,
        confirmButtonText: "Upgrade",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          stakeUpgradeToken(mint_address);
        }
      });
    }
  };

  const stakeUpgradeToken = useCallback(
    async (address: PublicKey) => {
      if (!publicKey) return;

      isloading(true);
      let arr: PublicKey[] = [address];
      const stakeUpgradeRes = await sendAndConfirmMultipleTransactions(
        await createStakeUpgradeTokenTransaction(connection, publicKey, arr)
      );
      if (unStakeFlag && stakeUpgradeRes) {
        onChange();
        Swal.fire({
          title: "You have successfully upgrade to next locking period",
        });
      }
    },
    [connection, publicKey, sendAndConfirmTransaction]
  );

  const withdrawRewards = useCallback(
    async (address: PublicKey) => {
      console.log(address.toString());
      if (!publicKey) return;
      isloading(true);
      let arr: PublicKey[] = [address];
      const withDrawres = await sendAndConfirmMultipleTransactions(
        await createWithdrawRewardTransaction(connection, publicKey, arr)
      );
      if (withDrawRewardFlag && withDrawres) {
        isloading(false);
        onChange();
        Swal.fire({
          title: "You have successfully withdrawn the rewards",
        });
        withDrawRewardFlag(true);
      }
    },
    [connection, publicKey, sendAndConfirmTransaction]
  );

  let openModal = async (nft_mint_address) => {
    let is_royalty_due = await check_royalty_status_nft(nft_mint_address);
    if (is_royalty_due === -1) {
      Swal.fire({
        title: "Radar API is down, Please try again after some time",
      });
    } else {
      if (!is_royalty_due) {
        const { value: locking_period_index } = await Swal.fire({
          title: "Select locking period",
          input: "select",
          inputOptions: {
            "0": "1 week",
            "1": "4 weeks",
            "2": "12 weeks",
            "3": "26 weeks",
            "4": "52 weeks",
          },
          inputPlaceholder: "Select locking period",
          showCancelButton: true,
          inputValidator: (value) => {
            if (!value) {
              return "You need to choose something!";
            }
          },
        });

        if (locking_period_index) {
          stakeToken(nft.mint, locking_period_index);
        }
      }
    }
  };

  const check_royalty_status_nft = async (mint_address) => {
    try {
      isloading(true);
      const response = await fetch(
        "https://api.niftybot.art/nft/royalty_status_nft/" +
          mint_address +
          "?summarized=true&cutoff=1681387200",
        { mode: "cors" }
      );
      let data = await response.json();
      console.log(data);
      isloading(false);
      console.log("Radar API Status Code : " + response.status);
      if (response.status === 200) {
        if (data["last_sale_due"] > 0) {
          let html =
            "<div className='current-locking-period-text'>DROID holders have voted in favor of enforcing secondary market royalties. You can choose to stake your NFT with unpaid royalties and receive 20% of the usual $DROID emissions and a one-month lock, or, pay the royalty now for full $DROID emissions and staking options.</div> ";

          Swal.fire({
            title: "Royalties are unpaid",
            html: html,
            showCancelButton: true,
            showConfirmButton: true,
            showDenyButton: true,
            confirmButtonText: "Pay Royalty",
            denyButtonText: "Stake (20% emission)",
          }).then((result) => {
            if (result.isConfirmed) {
              // handleRepayRoyalties()
              isloading(true);
              repayRoyalties(
                new PublicKey(data.mint),
                data.last_sale_due,
                data.metadata.seller_fee_basis_points,
                data.metadata.uri
              );
              // handleRepayRoyalties(
              //   new PublicKey(data.mint),
              //   data.last_sale_due,
              //   data.metadata.seller_fee_basis_points
              // );
            } else if (result.isDenied) {
              stakeToken(new PublicKey(mint_address), 5);
            }
          });
          return true;
        } else {
          return false;
        }
      } else {
        return -1;
      }
    } catch (e) {
      isloading(false);
      console.log(e);
      return -1;
    }
  };
  const repayRoyalties1 = async () => {
    console.log("Dd");
  };
  const repayRoyalties = async (
    mint,
    dueRoyalties,
    sellerFeeBasisPoints,
    metadataUri
  ) => {
    console.log(mint, dueRoyalties, sellerFeeBasisPoints, metadataUri);
    try {
      // const connection = new Connection(PUBLIC_RPC_URL, "confirmed");
      let metadata = await Metadata.load(
        connection,
        await Metadata.getPDA(mint)
      );
      console.log(metadata["data"]["data"]);
      console.log(metadataUri);
      // const nft = await fetch(metadataUri).then((e) => e.json());
      const nft = metadata["data"]["data"];
      // const nft = await metaplex.nfts().findByMint({ mintAddress: mint });
      if (nft) {
        console.log("Got Metadata", nft);
      } else {
        throw new Error("Unable to find nft");
      }
      // const lastValidBlockHeight = (await connection.getRecentBlockhash('max')).lastValidBlockHeight;
      const blockhashAndFee = await connection.getRecentBlockhash("max");
      // const lastValidBlockHeight = blockhashAndFee.context.slot;
      const blockhash = blockhashAndFee.blockhash;

      const transaction = new Transaction({
        feePayer: publicKey!,
        recentBlockhash: blockhash,
        // lastValidBlockHeight: lastValidBlockHeight
      });

      transaction.add(
        SystemProgram.transfer({
          fromPubkey: publicKey!,
          toPubkey: mint,
          lamports: 0,
        })
      );

      const lamportDueAmount = dueRoyalties * LAMPORTS_PER_SOL;
      console.log("Lampooorting", lamportDueAmount);

      nft.creators.forEach((creator) => {
        console.log(creator.address.toString(), creator.share / 100);
        console.log(
          "I will transfer " +
            lamportDueAmount * (creator.share / 100) +
            " Lamports to " +
            creator.address
        );
        const royaltyAmount = lamportDueAmount * (creator.share / 100);

        transaction.add(
          SystemProgram.transfer({
            fromPubkey: publicKey!,
            toPubkey: new PublicKey(creator.address),
            lamports: Math.round(royaltyAmount) + 1,
          })
        );
      });

      transaction.add(
        new TransactionInstruction({
          keys: [
            {
              pubkey: publicKey!,
              isSigner: true,
              isWritable: true,
            },
          ],
          data: Buffer.from(
            `[royalty-payment, mint:${mint}, amount:{dueRoyalties}]`,
            "utf-8"
          ),
          programId: new PublicKey(
            "MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr"
          ),
        })
      );

      console.log(transaction.instructions);

      console.log(connection);
      const transferSignature = await sendTransaction(transaction, connection, {
        skipPreflight: false,
      });

      console.log(transferSignature);
      isloading(false);
      Swal.fire({
        title:
          "Congrats, you have successfully paid the due royalties. You can now click 'Stake' and receive regular $DROID emissions. Note: it can take a moment of two for the transaction to register on the blockchain.",
      });
      return transferSignature;
    } catch (err) {
      isloading(false);
    }
  };

  const pay_royalties = async (mint_address) => {};
  const get_locking_period = (n) => {
    if ("duration" in n) {
      return n.duration;
    } else {
      return "";
    }
  };

  let showTimeStampAsReadable = (timestamp) => {
    let now = moment.utc();
    let day = moment.unix(Number(timestamp));
    let daysDiff = now.diff(day, "days");
    let hoursDiff = now.diff(day, "hours") % 24;
    let minutesDiff = now.diff(day, "minutes") % 60;
    let secondsDiff = now.diff(day, "seconds") % 60;
    let return_str =
      daysDiff +
      "d " +
      hoursDiff +
      "h " +
      minutesDiff +
      "m " +
      secondsDiff +
      "s ";
    return return_str;
  };

  let showUpgradeBtn = (nft_data) => {
    let not_upgrade = true;
    let utc_now = Math.floor(new Date().getTime() / 1000);
    let diff = utc_now - Number(nft_data.timestamp);

    if (diff < Number(nft_data.duration)) {
      not_upgrade = false;
    }

    return not_upgrade;
  };

  return (
    <Card>
      <Card.Body>
        {nft?.rank !== "" && (
          <div className="moon-rank">
            <span>⍜</span>
            {nft?.rank}
          </div>
        )}

        <img
          className="nft-img"
          src={nft.json.image}
          alt={nft.json.description}
        />
        <h3 className={staked ? "nft-nameStaked" : "nft-nameUnstaked"}>
          <a
            href={"https://solscan.io/token/" + nft.mint}
            target="_blank"
            rel="noreferrer"
          >
            {nft.data.name}
          </a>
        </h3>
        {staked ? (
          <>
            <div className="rewards">
              Rewards:{" "}
              <span className="counter">
                {calculateRewards(
                  nft as StakedData,
                  rewardDate,
                  rewardCalculator
                )}
              </span>{" "}
              $DROID
            </div>
            {/* <hr className="horizontal-line" /> */}

            <h3 className="stake-time">
              {showTimeStampAsReadable(nft.timestamp)}
            </h3>
            <h3 className="rewards">
              Locked For : {secondsToDhms(Number(nft.duration))}
            </h3>
            <Row>
              <Col md={7}>
                <Button
                  className="withdraw-btn"
                  onClick={() => withdrawRewards(nft.mint)}
                >
                  Claim $DROID
                </Button>
              </Col>
              <Col md={5}>
                <Button
                  className="withdraw-and-unstake-btn"
                  onClick={() => penality_and_reward_before_unstake(nft.mint)}
                >
                  Unstake
                </Button>
              </Col>
            </Row>
            {showUpgradeBtn(nft) ? (
              <Button className="stake" onClick={() => upgrade(nft.mint)}>
                Upgrade
              </Button>
            ) : (
              ""
            )}
          </>
        ) : (
          <div style={{ paddingTop: "10px" }}>
            <Button className="stake-btn" onClick={() => openModal(nft.mint)}>
              Stake
            </Button>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};
